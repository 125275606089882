/** @jsx jsx */
/** Shadowed Component */
import styled from "@emotion/styled"
import { jsx, Flex } from "theme-ui"


// Color variation for darker-blue gradient background:
// background: linear-gradient(rgba(0,110,255,0.9), rgba(0,110,255,0.3)); 
const SectionHeaderMain = styled.h1(
  {
    marginRight: '36px'
  },
  props => ({
    color: props.color
  })
)

const SectionHeaderSpan = styled.span`
  color: #ffcc66;
  margin-left: 5px;
`

export default ({ title, color }) => (
  <Flex
    sx={{
      flexDirection: [`column`],
    }}
  >
    <SectionHeaderMain color={color}>
      <SectionHeaderSpan>{"# "}</SectionHeaderSpan>{title}
    </SectionHeaderMain>
  </Flex>
)
