import React from "react"
import styled from "@emotion/styled"
import SkillsSection from "./sections/SkillSection"
import ProjectSection from "./sections/ProjectSection"
import FeaturedSection from "./sections/FeaturedSection"

const BodyContent = styled.div`
  overflow: hidden;
  margin-top: -30px;
`

const LandingBio = () => (
  <BodyContent>  
    <SkillsSection/>
    <FeaturedSection/>
    <ProjectSection/>
  </BodyContent>
)

export default LandingBio
