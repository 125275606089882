/** @jsx jsx */
/** Shadowed Component */
import styled from "@emotion/styled"
import { jsx } from "theme-ui"
import { FaCheck } from 'react-icons/fa';
import SectionHeader from "../shared/SectionHeader"

const FeaturedContent = styled.div`
  background: #036;
  // background-image: linear-gradient(#07C, #09F);
  margin: 0 auto;
  max-width: 100%;
  padding: 0;
  padding-top: 78;
  overflow: hidden;
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 3rem 1.0875rem;
`

const BodyText = styled.div`
  font-size: 20px;
  color: #FFF;
  margin-left: 20px;
`

const Button = styled.a`
  vertical-align: middle;
  margin-left: 15px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  padding: 10px 20px;
  border: 3px solid #FFF;
  border-radius: 40000px;
  font-weight: 900;
  color: #FFF;
  background-color: #F60;

  :hover {
    background-color: #FFF;
    color: #F60;
    border: 3px solid #F60;
  }
`

const Icon = styled.span`
  max-height: 1.4rem;
  padding-top: 2px;
  margin: 0 3px;
  vertical-align: middle;
`

const Link = styled.a`
  color: #FFF;
  text-decoration: underline;
`

const FeaturedSection = () => {
  return (
    <FeaturedContent>
      <Content id="featured">
      <SectionHeader title="featured" color="#FFF" />
        <div
          sx={{
            display: 'grid',
            gridGap: 5,
            gridTemplateColumns: [
              'auto',
              '1fr 1fr'
            ]
          }}>
          <BodyText>
            <p>
              {"Our latest open-source contribution is "}<Link href="https://ob-frontend.netlify.app/"><strong>{"ob-frontend"}</strong></Link>{", a Gatsby-based starter for serving the WordPress frontend as a React application.  Features include: "}
            </p>
            <p>
              <Icon><FaCheck color="FC6"/></Icon>&nbsp;{"Modern UI feat. "}<Link href="https://bloomer.js.org/"><strong>{"Bloomer"}</strong></Link>
            </p>
            <p>
            <Icon><FaCheck color="FC6"/></Icon>&nbsp;{"Rapid search feat. "}<Link href="https://www.apollographql.com/docs/react/"><strong>{"Apollo"}</strong></Link>
            </p>
            <p>
              <FaCheck color="FC6"/>&nbsp;{"Nested comment support"}
            </p>
          </BodyText>
          <div
            sx={{
              variant: `cards.primary`,
              borderLeft: `3px solid #e72727`,
              padding: `2rem 1rem`,
              mt: `-60px`
            }}>      
            <img src="https://storage.googleapis.com/ob-frontend/ob-frontend-red-logo.png"/>
            <Button href="https://ob-frontend.netlify.app/">
              {'Demo'}
            </Button>
            <Button href="https://github.com/onepagezen/ob-frontend">
              {'View on Github'}
            </Button>
          </div>
        </div>
      </Content>
      {/* <SectionDivider/> */}
    </FeaturedContent>
  )
}

export default FeaturedSection
