/** @jsx jsx */
/** Shadowed Component */
import styled from "@emotion/styled"
import { jsx } from "theme-ui"
import { FaExternalLinkAlt } from 'react-icons/fa'
import SectionHeader from "../shared/SectionHeader"

const ImageAlign = styled.div`
  // Added height here to keep paragraph text
  // level across columns
  height: 90px;

  // The following rules were added in order
  // to vertical align the images
  /* Internet Explorer 10 */
  display:-ms-flexbox;
  -ms-flex-pack:center;
  -ms-flex-align:center;

  /* Firefox */
  display:-moz-box;
  -moz-box-pack:center;
  -moz-box-align:center;

  /* Safari, Opera, and Chrome */
  display:-webkit-box;
  -webkit-box-pack:center;
  -webkit-box-align:center;

  /* W3C */
  display:box;
  box-pack:center;
  box-align:center;
`

const SkillsBackground = styled.div`
  margin: 0 auto;
  width: auto;
  max-width: 860px;
  padding: 0rem 1.0875rem 3rem;
`

const ProjectImage = styled.img`
  margin-bottom: 0;
  max-width: 225px;
`

const CardParagraph = styled.p`
  text-align: center;
`

const CardSpan = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`

const IconStyle = styled.span`
  position: relative;
  top: 5px;
  left: 5px;
  padding-right: 12px;
`

const SpanStyle = styled.a`
  text-transform: none !important;
  text-decoration: none;
  font-size: 18px !important;
  font-weight: 300 !important;
  text-decoration: none;
  color: #F60;
`

const NoSpanStyle = styled.span`
  cursor: not-allowed;
  color: #F60;
`

const FeaturedContent = styled.div`
  background: #FFF;
  margin: 0 auto;
  max-width: 100%;
  padding: 0;
  padding-top: 78;
  overflow: hidden;
  border-top: 3px solid #FC6;
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 3rem 1.0875rem 0rem;
`

const SectionParagraph = styled.p`
  padding: 0 20px;
  color: #FFF;;
  font-size: 20px;
`

const SectionDivider = styled.div`
  background: #FFF;
  margin: -13rem auto 0 auto;
  max-width: 100%;
  padding: 12rem 1.0875rem 0rem;
  // border-top: 3px solid #FC6;
  // padding-bottom: 0;
`

const ProjectSection = () => {

  const Item = ({ themeColor, isLink, image, summary }) => (
    <div
      sx={{
        variant: `cards.primary`,
        borderLeft: `3px solid ${themeColor}`
      }}>      
    <ImageAlign><ProjectImage src={image}/></ImageAlign>
    <CardParagraph>{summary}</CardParagraph>
    <CardSpan>{isLink}</CardSpan>
    </div>
  )
  
  const data =[
    {
      // onepagezen theme color #FC6
      "themeColor":"#FC6",
      "isLink":<NoSpanStyle><SpanStyle target="_blank" href='https://onepagezen.com'><IconStyle><FaExternalLinkAlt size={20} color={"#F60"}/></IconStyle><strong>{"onepagezen.com"}</strong></SpanStyle></NoSpanStyle>,
      "image":"https://storage.googleapis.com/opzsys_static/onepagezen_logo.png",
      "summary":"Check out our ever-expanding library of free cloud computing tutorials."
    },
    {
      // orchidboy theme color #421B5A
      "themeColor":"#421B5A",
      "isLink":<NoSpanStyle><SpanStyle target="_blank" href='https://orchidboy.com'><IconStyle><FaExternalLinkAlt size={20} color={"#F60"}/></IconStyle><strong>{"orchidboy.com"}</strong></SpanStyle></NoSpanStyle>,
      "image":"https://storage.googleapis.com/opzsys_static/orchidboy_logo.png",
      "summary":"Learn the skills required to grow a wide range of tropical houseplants."
    },
    {
      // ob-frontend theme color #E72727
      "themeColor":"#E72727",
      "isLink":<NoSpanStyle><SpanStyle target="_blank" href='https://github.com/onepagezen/ob-frontend'><IconStyle><FaExternalLinkAlt size={20} color={"#F60"}/></IconStyle><strong>{"View on Github"}</strong></SpanStyle></NoSpanStyle>,
      "image":"https://storage.googleapis.com/ob-frontend/ob-frontend-red-logo-long.png",
      "summary":"Modernize your stack with our open-source, React-based WordPress frontend."
    },
  ];
  var listItems = data.map(function(d, idx){
    return (
      <Item 
        key={idx}
        themeColor={d.themeColor}
        isLink={d.isLink}
        image={d.image} 
        summary={d.summary}
      />  
    )}
  )

  return (
    <FeaturedContent>
      <Content id="projects">
        <SectionHeader title="projects" color="#036" />
        {/* <SectionParagraph>
          The world has a lot to offer, and sharing our passions and knowledge with others through open-source projects is a great way to form long-lasting human connections.        
        </SectionParagraph> */}
        <SkillsBackground>
        <div
          sx={{
            variant: `grids.primary`
          }}
        >
          {listItems}
        </div>
      </SkillsBackground>
    </Content>
  <SectionDivider/>
  </FeaturedContent>
  )
}

export default ProjectSection
