/** @jsx jsx */
/** Shadowed Component */
import styled from "@emotion/styled"
import { jsx } from "theme-ui"
import { FaCode, FaHandsHelping, FaUsers } from 'react-icons/fa';
import SectionHeader from "../shared/SectionHeader"

const SectionParagraph = styled.p`
  padding: 0 20px;
  color: #FFF;
  font-size: 20px;
`

const BannerText = styled.h1`
  font-family: 'Permanent Marker';
  font-size: 2.2em;
  text-align: center;
  color: #FC6;
  margin-bottom: 0;
  padding: 3rem 1.0875rem 0;
  @media (max-width: 768px) {
    padding: 3.5rem 1.0875rem 3rem;
  }
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 0 1.0875rem 0;
`

const FeaturedContent = styled.div`
  background: #036;
  margin: 0 auto;
  max-width: 100%;
  padding: 0;
  overflow: hidden;
  border-bottom: 3px solid #FC6;
  border-top: 3px solid #FC6;
`

const SectionDivider = styled.div`
  background: #FFF;
  margin: -12rem auto 0 auto;
  max-width: 100%;
  padding: 14rem 1.0875rem 0rem;
  border-top: 3px solid #FC6;
`

const SkillsSection = () => {

  const Item = ({ themeColor, icon, title, summary }) => (
    <div
      sx={{
        variant: `cards.primary`,
        borderLeft: `3px solid ${themeColor}`
      }}>      
      {icon}
      <h3>{title}</h3>
      <p>{summary}</p>
    </div>
  )
  
  const data =[
    {
      // onepagezen theme color #FC6
      "themeColor":"#FC6",
      "icon":<FaCode size={80} color={"#FC6"}/>,
      "title":"Development",
      "summary":"Design and implement clean, user-centric solutions focused on quality and simplicity."
    },
    {
      // orchidboy theme color #421B5A
      "themeColor":"#FC6",
      "icon":<FaHandsHelping size={80} color={"#421B5A"}/>,
      "title":"Mentorship",
      "summary":"Inspire and provide growth opportunities through inclusive learning communities."
    },
    {
      // ob-frontend theme color #E72727
      "themeColor":"#FC6",
      "icon":<FaUsers size={80} color={"#E72727"}/>,
      "title":"Community",
      "summary":"Empower individuals to support the growth of the open source-software community."
    },
  ];

  var listItems = data.map(function(d, idx){
    return (
      <Item 
        key={idx}
        themeColor={d.themeColor}
        icon={d.icon}
        title={d.title}
        summary={d.summary}
      />  
    )}
  )

  return (
      <FeaturedContent>
        <BannerText className="banner-text">
          {"We're opzsys — developers of"}{<br/>}{"intuitive software tools"}{<br/>}{"and tutorials"}
        </BannerText>
        <Content id="mission">
        <SectionHeader title="mission" color="#FFF" />
        <SectionParagraph>
          {/* Since beginning my journey as a systems administrator nearly 5 years ago, I've learned that quality, inspiration, and efficiency are key ingredients to cultivating success.  As a developer, mentor, and sysadmin, I pledge to adhere to the following principles:*/}
          {"Branching from our roots as a dedicated cloud solutions provider, One Page Zen Systems, LLC ("}{<strong>{"opzsys"}</strong>}{") is a developer of online tools and educational resources.  As a community-based organization, we're committed to:"}
        </SectionParagraph>
        <div
          sx={{
            variant: `grids.primary`
          }}
        >
          {listItems}   
        </div>
      </Content>
      <SectionDivider/>
    </FeaturedContent>
  )
}

export default SkillsSection
